import {Injectable} from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  label?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  roles?: string[],
  cosme?: boolean;
  label?: string;
  children?: ChildrenItems[];
  // role?: string;
}

//INFO: menu config, 
// state: route,
// name: name of item (showed name),
// type: type of action: "link-> route", "sub-> show sub menu
// icon: icon showed on view
// roles: enabled for specific roles 
const MENUITEMS: Menu[] = [
  {
    state: 'library',
    name: 'LIBRARY_MENU',
    type: 'link',
    icon: 'fa fa-book icons',
    roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit'],
  },
  {
    state: 'licenses',
    name: 'LICENSES_MENU',
    type: 'link',
    icon: 'fa fa-id-card icons',
    roles: ['root', 'licence_manager'],
  },
  {
    state: 'library-validation',
    name: 'CHANGES_MENU',
    type: 'link',
    icon: 'fa fa-refresh icons',
    roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit'],
    cosme: true,
  },
  // {
  //     state: 'questionnaires',
  //     name: 'QUESTIONNAIRES_MENU',
  //     type: 'link',
  //     icon: 'fa fa-calendar-check-o icons',
  // },
  {
    state: 'groups',
    name: 'GROUP_MENU',
    type: 'link',
    icon: 'fa fa-globe icons',
    roles: ['root', 'admin_cosme'],
    cosme: true,
  },
  {
    state: 'branches',
    name: 'BRANCHES_MENU',
    type: 'link',
    icon: 'fa fa-map-o icons',
    roles: ['root', 'admin_cosme', 'admin_group'],
    cosme: true,
  },
  {
    state: 'units',
    name: 'UNITS_MENU',
    type: 'link',
    icon: 'fa fa-folder-open icons',
    roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch'],
    cosme: true,
  },
  {
    state: 'users',
    name: 'USERS_MENU',
    type: 'link',
    icon: 'fa fa-users icons',
    roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit'],
    cosme: true,
  },
  // {
  //     state: 'services',
  //     name: 'SERVICES_MENU',
  //     type: 'link',
  //     icon: 'fa fa-life-ring icons',
  // },
  {
    state: 'activity-sectors',
    name: 'SECTORS_MENU',
    type: 'link',
    icon: 'icon-paper-clip icons',
    roles: ['root', 'admin_cosme'],
    cosme: true,
  },
  {
    state: 'company-sizes',
    name: 'COMPANY_SIZES_MENU',
    type: 'link',
    icon: 'fa fa-area-chart icons',
    roles: ['root', 'admin_cosme'],
    cosme: true,
  },
  // {
  //     state: 'kpi-types',
  //     name: 'KPI_TYPES_MENU',
  //     type: 'link',
  //     icon: 'fa fa-line-chart icons',
  //     roles: ['root', 'admin_cosme'],
  //     cosme: true,
  // },
  {
    state: 'campaign-categories',
    name: 'CAMPAIGN_CATEGORIES',
    type: 'link',
    icon: 'fa fa-briefcase icons',
    roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit'],
    cosme: true,
  },
  {
    state: 'service-categories',
    name: 'SERVICE_CATEGORIES',
    type: 'link',
    icon: 'fa fa-tags icons',
    roles: ['root'],
  },
 /* {
    state: 'naf',
    name: 'NAF',
    type: 'link',
    icon: 'fa fa-tags icons',
    roles: ['root'],
  },*/
  {
    state: 'cosmes',
    name: 'COSMES_MENU',
    type: 'link',
    icon: 'fa fa-black-tie icons',
    roles: ['root'],
  },
  {
    state: '', // load id under demand
    name: 'COSME_DETAILS_MENU',
    type: 'cosme-details-link',
    icon: 'fa fa-black-tie icons',
    roles: ['admin_cosme'],
  },
  {
    state: 'knowledge',
    name: 'KNOWLEDGE',
    type: 'sub',
    icon: 'fa fa-graduation-cap icons',
    roles: ['root'],
    children: [
      {state: 'categories', name: 'CATEGORIES'},
      {state: 'subcategories', name: 'SUBCATEGORIES'},
      {state: 'articles', name: 'ARTICLES'},
    ]
  },
  {
    state: 'languages',
    name: 'LANGUAGE_MENU',
    type: 'link',
    icon: 'fa fa-language icons',
    roles: ['root'],
  },
  {
    state: 'settings',
    name: 'SETTING_MENU',
    type: 'link',
    icon: 'fa fa-cogs icons',
    roles: ['root', 'admin_cosme'],
  },
];

@Injectable()
export class MenuItems {

  //INFO: manage which menu items will be displayed
  getAll(role: string, cosmeId: string): Menu[] {
    const MENU: Menu[] = [];
    MENUITEMS.forEach(item => {
      if ((!item.roles || (item.roles.findIndex(r => r === role) !== -1))
        && (!item.cosme || cosmeId)) {
        if (item.name === 'COSME_DETAILS_MENU') {
          item.state = cosmeId;
        }
        MENU.push(item);
      }
    });
    return MENU;
  }

  add(menu: any) {
    MENUITEMS.push(menu);
  }
}
