import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
//
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslateService implements TranslateLoader {

  public config: any;
  public apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  //INFO: get JSON of translate archive
  getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.apiUrl}languages/BackOffice/${lang}`).pipe(
      map((response: JSON) => {
        return response;
      }));
  }

}
