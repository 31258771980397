import { Injectable } from '@angular/core';;
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
   providedIn: 'root'
})

//INFO: deprecated, not used for now in project
export class AuthService {

   userData: any;
   isLoggedIn = false;

   constructor() {
   }

   /*
    *  getLocalStorageUser function is used to get local user profile data.
    */
   getLocalStorageUser() {
      this.userData = JSON.parse(localStorage.getItem('userProfile'));
      if (this.userData) {
         this.isLoggedIn = true;
         return true;
      } else {
         this.isLoggedIn = false;
         return false;
      }
   }

   loginUser(value) {

   }

   /*
    * resetPassword is used to reset your password.
    */
   resetPassword(value) {

   }

   /*
    * logOut function is used to sign out . 
    */
   logOut() {

   }

   /*
    * setLocalUserProfile function is used to set local user profile data.
    */
   setLocalUserProfile(value) {
      localStorage.setItem('userProfile', JSON.stringify(value));
      this.isLoggedIn = true;
   }
}
