import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { WINDOW } from './window.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config: any;
  public apiUrl: string = environment.apiUrl;
  frontOfficeUrl: string = environment.frontOfficeUrl;
  public googleAnalyticsId: string = environment.googleAnalyticsId;

  constructor(private http: HttpClient,
    @Inject(WINDOW) private window: Window) {
  }

  //INFO: get endpoint routes in config.json 
  public getConfig(): Observable<any> {
    return this.http.get<any>(`assets/data/config.json?v=${environment.version}`).pipe(map((response) => {
      this.config = response;
    }));
  }

  //INFO: to manage local or deployed port 
  public getRootUrl() {
    if (this.window.location.port !== '80' && this.window.location.port !== '443') { // development
      return `${this.window.location.protocol}//${this.window.location.hostname}:${this.window.location.port}`;
    } else {
      return `${this.window.location.protocol}//${this.window.location.hostname}`;
    }
  }
}
