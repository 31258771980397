import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CdkTableModule} from '@angular/cdk/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {AgmCoreModule} from '@agm/core';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SidebarModule} from 'ng-sidebar';
import {ToastrModule} from 'ngx-toastr';
import 'hammerjs';

import {AuthService} from './service/auth/auth.service';
import {AppComponent} from './app.component';
import {RoutingModule} from './app-routing.module';
import {ConfigModule} from './config/config.module';
import {ErrorHandlingModule} from './error-handling/error-handling.module';
import {HttpRequestIndicatorModule} from './http-request-indicator/http-request-indicator.module';
import {LoadingComponent} from './http-request-indicator/components/loading/loading.component';
import {MenuToggleModule} from './core/menu/menu-toggle.module';
import {MenuItems} from './core/menu/menu-items/menu-items';
import {PageTitleService} from './core/page-title/page-title.service';
import {OverlayModule} from '@angular/cdk/overlay';
import {ConfirmDialogModule} from './confirm-dialog/confirm-dialog.module';
import {ConfirmDialogComponent} from './confirm-dialog/components/confirm-dialog.component';
import {TagInputModule} from 'ngx-chips';
// import { environment } from '../environments/environment';
import {TranslateService} from './config/services/translate.service';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';
import {SentryErrorHandler} from './error-handling/sentry-error-handler';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import { GoogleAnalyticsService } from './config/services/google-analytics.service';

/********** Custom option for ngx-translate ******/
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);
// }

// export const firebase = {
// 	apiKey: "AIzaSyAYQ701NLzFMFFtx-A71OzNfORfJhR1RvI",
// 	authDomain: "chankya-e0e7a.firebaseapp.com",
// 	databaseURL: "https://chankya-e0e7a.firebaseio.com",
// 	projectId: "chankya-e0e7a",
// 	storageBucket: "chankya-e0e7a.appspot.com",
// 	messagingSenderId: "531424365001"
// }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const perfectScrollbarConfig: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    SidebarModule.forRoot(),
    RoutingModule,
    TourNgBootstrapModule.forRoot(),
    NgbModalModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk' }),
    PerfectScrollbarModule,
    MenuToggleModule,
    HttpClientModule,
    OverlayModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateService,
        // useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    TagInputModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true
    }),
    ConfigModule,
    ErrorHandlingModule,
    HttpRequestIndicatorModule,
    ConfirmDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  declarations: [
    AppComponent,
    TermsConditionsComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    MenuItems,
    GoogleAnalyticsService,
    PageTitleService,
    AuthService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  entryComponents: [
    LoadingComponent,
    ConfirmDialogComponent
  ]
})
export class AppModule {
}
