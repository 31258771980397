import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfigResolveService } from './config/services/config-resolve.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
// import { AuthGuardService } from './authentication/services/auth-guard.service';

export const AppRoutes: Routes = [
    // {
    //    path: 'session',
    //    loadChildren: './session/session.module#SessionDemoModule'
    // },
    {
        path: 'auth',
        loadChildren: './authentication/authentication.module#AuthenticationModule',
        resolve: {
            config: ConfigResolveService
        }
    },
    {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
        // canActivate: [AuthGuardAdviserService],
        resolve: {
            config: ConfigResolveService,
        },
        data: { closeRouteCommand: ['../'] }
    },
    
    {
        path: '',
        loadChildren: './backoffice/backoffice.module#BackofficeModule',
        // canActivate: [AuthGuardService],
        resolve: {
            config: ConfigResolveService
        }
    },
    {
        path: '**',
        redirectTo: 'auth'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes)
    ],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule { }

